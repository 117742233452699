<template>
    <div>
        <v-row>
            <v-col v-if="$vuetify.breakpoint.width < 900" cols="12" sm="12" md="6" lg="4" xl="4">
                <v-carousel :show-arrows="false" height="370" hide-delimiter-background
                            :continuous="false"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                            class="carousel-circle align-content-stretch d-flex">
                    <v-carousel-item v-for="n in 6"
                                     :key="n"
                                     :active-class="'active---class'">
                        <v-card v-for="i in 2" :key="i" elevation="0" class="mr-4 mr-md-0 bg-bg mb-15" height="310px" color="white">
                            <div class="text-decoration-none d-flex flex-column link_popularNewBuildings"
                            >
                                <v-col>
                                    <v-skeleton-loader class="mb-6"
                                                       type="image@2"
                                                       width="300px"
                                                       height="250px"
                                    ></v-skeleton-loader>
                                </v-col>
                            </div>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
            <v-col v-else cols="12" sm="12" md="6" lg="4" xl="4"
                   v-for="n in 6" :key="n">
                <v-skeleton-loader
                                   type="image@2"
                                   height="307.5"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "LoadingGrid",
}
</script>

<style scoped>

</style>
